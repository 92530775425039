/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&display=swap');
@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
html,
body {height: 100%;font-family: 'Montserrat' !important;}
body,
.mat-checkbox,
.mat-input-element,
.mat-mdc-form-field,
.mat-form-field-label,
.mdc-floating-label,
.mat-card,
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-mdc-list-item,
.mat-fab,
.mat-mini-fab,
.mat-expansion-panel,
.mat-expansion-panel-header,
.mat-sidenav,
.mat-expansion-panel-header-description {font-family: 'Montserrat' !important;}
.mat-mdc-card {padding: 1rem;font-family: 'Montserrat' !important;}
.mat-snack-bar-container-success {background-color: green;font-family: 'Montserrat' !important;}
.mat-snack-bar-container-error {background: #f44336;font-family: 'Montserrat' !important;}
.mat-snack-bar-container-error,
.mat-snack-bar-container-success {
    &,button {color: white;}
}
.mat-mdc-form-field-subscript-wrapper {font-family: 'Montserrat' !important;}
.mat-mdc-paginator , .mat-mdc-select {font-family: 'Montserrat' !important;}

body {background: rgba(237, 242, 244, 0.97) !important;font-family: 'Montserrat' !important;}
.content-top-heading h3 {font-family: Montserrat;font-size: 24px;font-weight: normal;font-stretch: normal;font-style: normal;line-height: 1.43;letter-spacing: normal;text-align: left;color: #43425d;margin: 0 0 0 0;}
.part-1 .mat-expansion-panel-body {background: whitesmoke !important;font-family: 'Montserrat' !important;}
.mat-mdc-dialog-actions.mdc-dialog__actions.right-fix {padding-bottom: 0 !important;}
.mat-mdc-dialog-surface.mdc-dialog__surface {height: auto !important;}
.mat-expansion-panel-body {padding: 0 15px 15px !important;}
.right {display: flex;align-items: center;}
/*.my-select .mat-mdc-text-field-wrapper {height: 35px;}*/
.my-select .mat-mdc-form-field-infix.ng-tns-c3899553609-2 {padding: 6px 0 0 0;font-size: 1;}
.flx-tbl mat-slide-toggle {position: relative;bottom: 3px;}
.my-eshop .eshop-full-img {max-width: 140px !important;margin: auto !important;display: block !important;}
.Add-more-Photo img {max-width: 100px;}
.mat-mdc-dialog-container .mdc-dialog__surface {min-height: 160px !important;}
.rel-4.my-cls mat-form-field {margin: 0;height: 35px;}
.rel-4.my-cls .mat-mdc-text-field-wrapper {height: 35px;}
.rel-4.my-cls .mat-mdc-form-field-infix {padding: 6px 0 0 0;}
.over-flow table {width: 100%;font-family: 'Montserrat' !important;}
.qr-wd {width: 70px !important;margin: 0 0 5px 0;}
.image-eshop {margin: 0 0 15px 0;}
.all-view-head {font-family: Montserrat;font-size: 24px;font-weight: normal;font-stretch: normal;font-style: normal;line-height: 1.43;letter-spacing: normal;text-align: left;color: #43425d;margin: 30px 0 15px 0;}
.container {max-width: 100% !important;}
.Listings-p {font-family: Montserrat;font-size: 24px;font-weight: normal;font-stretch: normal;font-style: normal;line-height: 1.43;letter-spacing: normal;text-align: left;color: #43425d;margin: 30px 0 15px 0;}
.cdk-overlay-pane {height: auto !important;overflow: auto !important;}
.sb-circles-dark.sb-group {justify-content: center;}
@media(max-width: 991px) {
    .content-top-heading h3 {margin: 20px 0 0 0;}
    .rel-4.my-cls .mat-mdc-form-field-infix {padding: 6px 0 0 0;}
    // app-network-listing mat-list .mdc-list-item__content .mdc-list-item__secondary-text::before {
    //     display: inline !important;
    // }
}

@media(max-width: 767px) {
    app-network-listing mat-list-item.mat-mdc-list-item.mdc-list-item.mdc-list-item--with-three-lines {min-height: 140px !important;}
    .card-row.ng-star-inserted {margin: 15px 0 0 0;}
    .rel-4.my-cls .mat-mdc-form-field-infix {padding: 6px 0 0 0 !important;}
}

.content-top-white-2[_ngcontent-ng-c2653172334] {justify-content: space-between;}


/* Importing Bootstrap SCSS file. */

@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */

@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
ngx-intl-tel-input {font-family: 'Montserrat' !important;
    outline: none!important;
    .iti.iti--allow-dropdown.separate-dial-code {
        line-height: initial!important;
        /* height: 35px!important;*/
        outline: none!important;
        input#phone {/*height: 35px;*/outline: none!important;font-family: 'Montserrat' !important;}
    }
    .dropdown-menu.show.country-dropdown {min-width: 270px!important;width: 270px!important;}
}

.field.StripeElement {border-radius: 10px;background-color: #edf2f4;height: 54px;border: none;font-family: Montserrat;font-size: 14px;font-weight: bold;font-stretch: normal;font-style: normal;line-height: 3.79;letter-spacing: 0.7px;text-align: left;color: rgba(43, 45, 66, 0.97);width: 100%;padding: 14px 6px;}
body {font-family: 'Montserrat' !important;}

.stepper-horizontal {display: table;width: 100%;margin: 0 auto;}
.stepper-horizontal .step {display: table-cell;position: relative;padding: 1.5rem;z-index: 2;/*width: 17%;*/}
.stepper-horizontal .step:last-child .step-bar-left,
.stepper-horizontal .step:last-child .step-bar-right {display: none;}
.stepper-horizontal .step .step-circle {width: 2rem;height: 2rem;margin: 0 auto;border-radius: 50%;text-align: center;line-height: 1.75rem;font-size: 1rem;font-weight: 600;z-index: 2;border: 2px solid #D9E2EC;}
.stepper-horizontal .step.done .step-circle {background-color: #199473;border: 2px solid #199473;color: #FFFFFF;}
.stepper-horizontal .step.done .step-circle:before {font-family: 'FontAwesome';font-weight: 100;content: "\f00c";}
.stepper-horizontal .step.done .step-circle * {display: none;}
.stepper-horizontal .step.done .step-title {color: #102A43;}
.stepper-horizontal .step.editing .step-circle {background: #ffffff;border-color: #199473;color: #199473;}
.stepper-horizontal .step.editing .step-title {color: #199473;text-decoration: underline;}
.stepper-horizontal .step .step-title {margin-top: 1rem;font-size: 1rem;font-weight: 600;}
.stepper-horizontal .step .step-title,
.stepper-horizontal .step .step-optional {text-align: center;color: #829AB1;width: 100px;}
.stepper-horizontal .step .step-optional {font-size: 0.75rem;font-style: italic;color: #9FB3C8;}
.stepper-horizontal .step .step-bar-left,
.stepper-horizontal .step .step-bar-right {position: absolute;top: calc(2rem + 5px);height: 5px;background-color: #D9E2EC;border: solid #D9E2EC;border-width: 2px 0;}
.stepper-horizontal .step .step-bar-left {width: calc(100% - 2rem);left: 50%;margin-left: 1rem;z-index: -1;}
.stepper-horizontal .step .step-bar-right {width: 0;left: 50%;margin-left: 1rem;z-index: -1;transition: width 500ms ease-in-out;}
.stepper-horizontal .step.done .step-bar-right {background-color: #199473;border-color: #199473;z-index: 3;width: calc(100% - 1rem);}

// ::-webkit-scrollbar {
//     width: 15px;
//     background:transparent;
// }

// ::-webkit-scrollbar-track {
//     // -webkit-box-shadow: inset 0 0 6px #00b7e8; 
//     border-radius: 15px;
// }

// ::-webkit-scrollbar-thumb {
//     border-radius: 15px;
//     -webkit-box-shadow: inset 0 0 6px #00000080; 
//     // background:#00b7e8;
// } 


@media (max-width:767px) {
    .mat-mdc-paginator {display: block;position: absolute !important;margin-top: 20px;width: auto;margin: 20px 0px !important;padding: 10px 20px 10px 0 !important;}
    .mat-mdc-paginator-page-size {margin-right: 0px !important;}
}